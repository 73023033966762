import React, {Component} from "react";
import FormPengumuman from "../formPengumuman"
import http from "../../http-common";
import AuthenticationDataService from "../../services/authentication.service";

export default class UpdatePengumuman extends Component{
    constructor(props) {
        super(props);
        this.state = {
            judul:"",
            tglDibuat:Date,
            pesan:"",
            id:"",
            role:"",
        }
    }

    componentDidMount() {
        this.loadUser()
        const pathname = window.location.href.split("/update-pengumuman/")[1];
        this.handleDetailPengumuman(pathname);
    }

    async handleDetailPengumuman(item,event){
        // event.preventDefault()
        try{
            const {data}= await http.get("/api/pengumuman/"+ item);
            this.setState({judul: data.data.judul, tglDibuat: data.data.tglDibuat, pesan: data.data.isiPengumuman,
            id: data.data.id}) //tglDibuat == tglDisunting
           
        }catch(error){
            console.log("Oops terjadi masalah pada server")

        }
    }

    async loadUser(){
        try {
            let token = localStorage.getItem("ssoui");
            token = JSON.parse(token);
            if (token !== null){
                const response = await AuthenticationDataService.profile(token);
                this.setState({role:response.data.role});
            }

        } catch {
            console.log("Load user error!");
        }
    }

    render(){
        return(
            <div>
                {this.state.role === "staf"?
                <FormPengumuman judul={this.state.judul} pesan={this.state.pesan} id={this.state.id}
                tglDibuat={this.state.tglDibuat}/>:
                <div></div>
                }
            
            </div>
           
        )
    }
}