import http from "../http-common";

class VisitorTrackingService {
    countVisit() {
        http.get('https://geolocation-db.com/json/')
            .then(res => {
                let formData = new FormData();
                formData.append('ip', res.data.IPv4);
                http.post("/api/metriks/pengunjung/", formData);
            })

        // return await axios.post("/count-visit", res.data.IPv4);
    }

}
export default new VisitorTrackingService();