import axios from "axios";

export default axios.create({
    // baseURL: "http://localhost:8000",
    // baseURL: "https://propensi-a03-staging.herokuapp.com",
    baseURL: "https://petra.socialwelfare.fisip.ui.ac.id/backend",

    headers: {
        "Content-type": "application/json"
    } //
});
