import http from "../http-common";

class AuthenticationDataService {
    user(token) {
        return http.post("/api/user", token);
    }

    profile(token) {
        return http.post("/api/profile", token);
    }
}
export default new AuthenticationDataService();